import React from "react"
import Layout from "../../components/global/Layout"

const BlivAnnoncoer = () => {
  return (
    <Layout
      metaTitle="Cookie- og privatlivspolitik"
      metaDescription="Læs vores cookie- og privatlivspolitik"
      slug="/information/cookie-og-privatlivspolitik/"
    >
      <div className="container my-xl">
        <section className="text-content">
          <h1>Cookie- og privatlivspolitik</h1>
          <h4>Introduktion</h4>
          <p>
            Når du besøger vores website indsamles der oplysninger om dig, som
            bruges til at tilpasse og forbedre vores indhold og til at øge
            værdien af de annoncer, der vises på siden. Hvis du ikke ønsker, at
            der indsamles oplysninger, bør du slette dine cookies (
            <a href="http://minecookies.org/cookiehandtering">se vejledning</a>)
            og undlade videre brug af websitet. Nedenfor har vi uddybet, hvilke
            informationer der indsamles, deres formål og hvilke tredjeparter,
            der har adgang til dem.
          </p>

          <h4>Cookies</h4>
          <p>
            Websitet anvender ”cookies”, der er en tekstfil, som gemmes på din
            computer, mobil el. tilsvarende med det formål at genkende den,
            huske indstillinger, udføre statistik og målrette annoncer. Cookies
            kan ikke indeholde skadelig kode som f.eks. virus.
          </p>

          <p>
            Det er muligt at slette eller blokere for cookies. Se vejledning: 
            <a href="http://minecookies.org/cookiehandtering">
              http://minecookies.org/cookiehandtering
            </a>
          </p>

          <p>
            Hvis du sletter eller blokerer cookies vil annoncer kunne blive
            mindre relevante for dig og optræde hyppigere. Du kan desuden
            risikere at websitet ikke fungerer optimalt samt at der er indhold,
            du ikke kan få adgang til.
          </p>

          <p>
            Websitet indeholder cookies fra tredjeparter, der i varierende
            omfang kan omfatte:
          </p>
          <ul>
            <li>
              Google Analytics – statistikker for besøgsantal og lignende.
              Bruges til at gøre brugeroplevelsen bedre. Hvis du ikke ønsker
              cookies fra Google Analytics, kan du framelde dig dem her.
            </li>
          </ul>
          <h3>Personoplysninger</h3>
          <h4>Generelt</h4>
          <p>
            Personoplysninger er alle slags informationer, der i et eller andet
            omfang kan henføres til dig. Når du benytter vores website indsamler
            og behandler vi en række sådanne informationer. Det sker f.eks. ved
            alm. tilgang af indhold, hvis du tilmelder dig vores nyhedsbrev,
            deltager i konkurrencer eller undersøgelser, registrerer dig som
            bruger eller abonnent, øvrig brug af services eller foretager køb
            via websitet.
          </p>

          <h4>Periode for opbevaring</h4>
          <p>
            Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til
            lovgivningen, og vi sletter dem, når de ikke længere er nødvendige.
            Perioden afhænger af karakteren af oplysningen og baggrunden for
            opbevaring. Det er derfor ikke muligt at angive en generel tidsramme
            for, hvornår informationer slettes.
          </p>

          <h4>Videregivelse af oplysninger</h4>
          <p>
            Data om din brug af websitet, hvilke annoncer, du modtager og evt.
            klikker på, geografisk placering, køn og alderssegment m.v.
            videregives til tredjeparter i det omfang disse oplysninger er
            kendt. Du kan se hvilke tredjeparter, der er tale om, i afsnittet om
            ”Cookies” ovenfor. Oplysningerne anvendes til målretning af
            annoncering.
          </p>

          <h4>Indsigt og klager</h4>
          <p>
            Du har ret til at få oplyst, hvilke personoplysninger, vi behandler
            om dig. Du kan desuden til enhver tid gøre indsigelse mod, at
            oplysninger anvendes. Du kan også tilbagekalde dit samtykke til, at
            der bliver behandlet oplysninger om dig. Hvis de oplysninger, der
            behandles om dig, er forkerte har du ret til at de bliver rettet
            eller slettet. Henvendelse herom kan ske til:
            [kontakt@opholdsguide.dk]. Hvis du vil klage over vores behandling
            af dine personoplysninger, har du også mulighed for at tage kontakt
            til <a href="http://www.datatilsynet.dk/">Datatilsynet</a>.
          </p>

          <h4>Udgiver</h4>
          <p>Websitet ejes og administreres af:</p>
          <p>Opholdsguide</p>
          <p>CVR: 37884804</p>
          <p>Sluseholmen</p>
          <p>2450, København SV</p>

          <p>
            Email:{" "}
            <a href="mailto:kontakt@opholdsguide.dk">kontakt@opholdsguide.dk</a>
          </p>
        </section>
      </div>
    </Layout>
  )
}

export default BlivAnnoncoer
